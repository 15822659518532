@charset "UTF-8";

@font-face {
	font-family: 'untitled-font-2';
	src: url('fonts/untitled-font-2.eot');
	src:
		url('fonts/untitled-font-2.eot?#iefix') format('embedded-opentype'),
		url('fonts/untitled-font-2.woff') format('woff'),
		url('fonts/untitled-font-2.ttf') format('truetype'),
		url('fonts/untitled-font-2.svg#untitled-font-2') format('svg');
	font-weight: normal;
	font-style: normal;
}

[data-icon]:before {
	font-family: 'untitled-font-2' !important;
	content: attr(data-icon);
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^='icon-']:before,
[class*=' icon-']:before {
	font-family: 'untitled-font-2' !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-mv-360:before {
	content: '\61';
}
.icon-mv-activities:before {
	content: '\62';
}
.icon-mv-aircon:before {
	content: '\63';
}
.icon-mv-apple:before {
	content: '\64';
}
.icon-mv-arrow-back:before {
	content: '\65';
}
.icon-mv-arrow-down:before {
	content: '\66';
}
.icon-mv-arrow-left:before {
	content: '\67';
}
.icon-mv-arrow-right:before {
	content: '\68';
}
.icon-mv-arrow-up:before {
	content: '\69';
}
.icon-mv-assistance:before {
	content: '\6a';
}
.icon-mv-backpack:before {
	content: '\6b';
}
.icon-mv-bag:before {
	content: '\6c';
}
.icon-mv-bag-shopping:before {
	content: '\6d';
}
.icon-mv-beach:before {
	content: '\6e';
}
.icon-mv-bed-double:before {
	content: '\6f';
}
.icon-mv-beer:before {
	content: '\70';
}
.icon-mv-book:before {
	content: '\71';
}
.icon-mv-breakfast:before {
	content: '\72';
}
.icon-mv-bus:before {
	content: '\73';
}
.icon-mv-calendar:before {
	content: '\74';
}
.icon-mv-calender2020:before {
	content: '\75';
}
.icon-mv-car-parking:before {
	content: '\76';
}
.icon-mv-cars:before {
	content: '\77';
}
.icon-mv-chat:before {
	content: '\78';
}
.icon-mv-checkmark:before {
	content: '\79';
}
.icon-mv-cities:before {
	content: '\7a';
}
.icon-mv-cocktail:before {
	content: '\41';
}
.icon-mv-cola-de-globo:before {
	content: '\42';
}
.icon-mv-creditcard:before {
	content: '\43';
}
.icon-mv-cross:before {
	content: '\44';
}
.icon-mv-currencies:before {
	content: '\45';
}
.icon-mv-date-all:before {
	content: '\46';
}
.icon-mv-date-april:before {
	content: '\47';
}
.icon-mv-date-august:before {
	content: '\48';
}
.icon-mv-date-december:before {
	content: '\49';
}
.icon-mv-date-february:before {
	content: '\4a';
}
.icon-mv-date-january:before {
	content: '\4b';
}
.icon-mv-date-july:before {
	content: '\4c';
}
.icon-mv-date-june:before {
	content: '\4d';
}
.icon-mv-date-march:before {
	content: '\4e';
}
.icon-mv-date-may:before {
	content: '\4f';
}
.icon-mv-date-november:before {
	content: '\50';
}
.icon-mv-date-october:before {
	content: '\51';
}
.icon-mv-date-september:before {
	content: '\52';
}
.icon-mv-destiny-africa:before {
	content: '\53';
}
.icon-mv-destiny-all:before {
	content: '\54';
}
.icon-mv-destiny-asia:before {
	content: '\55';
}
.icon-mv-destiny-brazil:before {
	content: '\56';
}
.icon-mv-destiny-caribbean:before {
	content: '\57';
}
.icon-mv-destiny-europe:before {
	content: '\58';
}
.icon-mv-destiny-southamerica:before {
	content: '\59';
}
.icon-mv-destiny-usa:before {
	content: '\5a';
}
.icon-mv-dinner:before {
	content: '\30';
}
.icon-mv-dinner-high:before {
	content: '\31';
}
.icon-mv-egg:before {
	content: '\32';
}
.icon-mv-exlamation-circle:before {
	content: '\33';
}
.icon-mv-eye:before {
	content: '\34';
}
.icon-mv-family:before {
	content: '\36';
}
.icon-mv-family-restaurant:before {
	content: '\37';
}
.icon-mv-fastfood:before {
	content: '\38';
}
.icon-mv-flight-class:before {
	content: '\39';
}
.icon-mv-flights:before {
	content: '\21';
}
.icon-mv-flower:before {
	content: '\22';
}
.icon-mv-gasoline:before {
	content: '\23';
}
.icon-mv-gift:before {
	content: '\24';
}
.icon-mv-globo:before {
	content: '\25';
}
.icon-mv-globo-circle:before {
	content: '\26';
}
.icon-mv-gym:before {
	content: '\27';
}
.icon-mv-hand-bag:before {
	content: '\28';
}
.icon-mv-hotel:before {
	content: '\29';
}
.icon-mv-instagram-square:before {
	content: '\2a';
}
.icon-mv-jumping:before {
	content: '\2b';
}
.icon-mv-landscape:before {
	content: '\2c';
}
.icon-mv-lense:before {
	content: '\2d';
}
.icon-mv-list:before {
	content: '\2e';
}
.icon-mv-location-1:before {
	content: '\3a';
}
.icon-mv-mask:before {
	content: '\3b';
}
.icon-mv-medal:before {
	content: '\3c';
}
.icon-mv-mens:before {
	content: '\3d';
}
.icon-mv-money:before {
	content: '\3e';
}
.icon-mv-mountain:before {
	content: '\3f';
}
.icon-mv-packages:before {
	content: '\40';
}
.icon-mv-parking:before {
	content: '\5b';
}
.icon-mv-plane-ida:before {
	content: '\5f';
}
.icon-mv-plane-vuelta:before {
	content: '\60';
}
.icon-mv-price-circle:before {
	content: '\7b';
}
.icon-mv-questionmark:before {
	content: '\7c';
}
.icon-mv-questionmark-circle:before {
	content: '\7d';
}
.icon-mv-safari:before {
	content: '\7e';
}
.icon-mv-selection:before {
	content: '\5c';
}
.icon-mv-sun:before {
	content: '\e001';
}
.icon-mv-sunglasses:before {
	content: '\e002';
}
.icon-mv-swimming-pool:before {
	content: '\e003';
}
.icon-mv-ta-empty:before {
	content: '\e004';
}
.icon-mv-ta-full:before {
	content: '\e005';
}
.icon-mv-ta-half:before {
	content: '\e006';
}
.icon-mv-taxi:before {
	content: '\e007';
}
.icon-mv-temperature:before {
	content: '\e008';
}
.icon-mv-timeleft:before {
	content: '\e009';
}
.icon-mv-train:before {
	content: '\e00a';
}
.icon-mv-transport:before {
	content: '\e00b';
}
.icon-mv-trees:before {
	content: '\e00c';
}
.icon-mv-twitter-square:before {
	content: '\e00d';
}
.icon-mv-user:before {
	content: '\e00e';
}
.icon-mv-user-circle:before {
	content: '\e00f';
}
.icon-mv-users:before {
	content: '\e010';
}
.icon-mv-voltage:before {
	content: '\e011';
}
.icon-mv-wifi:before {
	content: '\e012';
}
.icon-bookmark:before {
	content: '\e014';
}
.icon-bookmark-o:before {
	content: '\e015';
}
.icon-briefcase:before {
	content: '\e016';
}
.icon-camera:before {
	content: '\e017';
}
.icon-download:before {
	content: '\e018';
}
.icon-file:before {
	content: '\e019';
}
.icon-file-image-o:before {
	content: '\e01a';
}
.icon-file-pdf-o:before {
	content: '\e01b';
}
.icon-file-text:before {
	content: '\e01c';
}
.icon-file-powerpoint-o:before {
	content: '\e01d';
}
.icon-file-video-o:before {
	content: '\e01e';
}
.icon-file-word-o:before {
	content: '\e01f';
}
.icon-filter:before {
	content: '\e020';
}
.icon-heart:before {
	content: '\e021';
}
.icon-heart-o:before {
	content: '\e022';
}
.icon-medkit:before {
	content: '\e023';
}
.icon-paw:before {
	content: '\e024';
}
.icon-plug:before {
	content: '\e025';
}
.icon-suitcase:before {
	content: '\e026';
}
.icon-trash:before {
	content: '\e027';
}
.icon-university:before {
	content: '\e028';
}
.icon-wheelchair:before {
	content: '\e029';
}
.icon-mv-beer-bottle:before {
	content: '\e02a';
}
.icon-mv-chicken:before {
	content: '\e02b';
}
.icon-mv-cheese:before {
	content: '\e02c';
}
.icon-mv-cigarrete:before {
	content: '\e02d';
}
.icon-mv-bread:before {
	content: '\e02e';
}
.icon-mv-cola-bottle:before {
	content: '\e030';
}
.icon-mv-doctor:before {
	content: '\e031';
}
.icon-mv-dress-shoe:before {
	content: '\e032';
}
.icon-mv-eggs:before {
	content: '\e033';
}
.icon-mv-plug-type-i:before {
	content: '\5d';
}
.icon-mv-plug-type-j:before {
	content: '\5e';
}
.icon-mv-spray:before {
	content: '\e000';
}
.icon-mv-toilet-paper:before {
	content: '\e013';
}
.icon-mv-tomatoe:before {
	content: '\e034';
}
.icon-mv-paxs:before {
	content: '\e035';
}
.icon-mv-phone:before {
	content: '\e036';
}
.icon-mv-pills:before {
	content: '\e037';
}
.icon-mv-men-haircut:before {
	content: '\e038';
}
.icon-mv-potatoes:before {
	content: '\e039';
}
.icon-mv-plug-type-l:before {
	content: '\e03a';
}
.icon-mv-plug-type-k:before {
	content: '\e03b';
}
.icon-mv-plug-type-h:before {
	content: '\e03c';
}
.icon-mv-plug-type-g:before {
	content: '\e03d';
}
.icon-mv-plug-type-f:before {
	content: '\e03e';
}
.icon-mv-plug-type-e:before {
	content: '\e03f';
}
.icon-mv-plug-type-d:before {
	content: '\e040';
}
.icon-mv-plug-type-c:before {
	content: '\e041';
}
.icon-mv-plug-type-b:before {
	content: '\e042';
}
.icon-mv-plug-type-a:before {
	content: '\e043';
}
.icon-mv-milk:before {
	content: '\e044';
}
.icon-mv-mobile-price:before {
	content: '\e045';
}
.icon-mv-pants:before {
	content: '\e046';
}
.icon-mv-rice:before {
	content: '\e047';
}
.icon-mv-tampon:before {
	content: '\e048';
}
.icon-mv-shampoo:before {
	content: '\e049';
}
.icon-mv-sport-shoe:before {
	content: '\e04a';
}
.icon-mv-suitcase:before {
	content: '\e04b';
}
.icon-mv-summer-dress:before {
	content: '\e04c';
}
.icon-mv-tooth-paste:before {
	content: '\e04d';
}
.icon-mv-circuit:before {
	content: '\e050';
}
.icon-mv-adventure:before {
	content: '\e051';
}
.icon-mv-food-wine:before {
	content: '\e052';
}
.icon-mv-night-club:before {
	content: '\e053';
}
.icon-mv-world-city:before {
	content: '\e056';
}
.icon-mv-facebook-square:before {
	content: '\35';
}
.icon-mv-writing:before {
	content: '\e04e';
}
.icon-mv-cancel:before {
	content: '\e04e';
}
.icon-mv-bill:before {
	content: '\e057';
}
.icon-mv-delete:before {
	content: '\e058';
}
.icon-mv-google:before {
	content: '\e059';
}
.icon-mv-flight-voucher:before {
	content: '\e05a';
}
.icon-mv-details:before {
	content: '\e05b';
}
.icon-mv-writing:before {
	content: '\e05c';
}
.icon-mv-concert:before {
	content: '\e05d';
}
.icon-mv-calender-cross:before {
	content: '\e05e';
}
.icon-mv-children:before {
	content: '\e05f';
}
.icon-mv-checkmark-round:before {
	content: '\e060';
}
.icon-mv-arrow-faster:before {
	content: '\e061';
}
.icon-mv-arrow-fast:before {
	content: '\e062';
}
.icon-mv-dj:before {
	content: '\e063';
}
.icon-mv-guide-round:before {
	content: '\e064';
}
.icon-mv-guide:before {
	content: '\e065';
}
.icon-mv-location-2:before {
	content: '\e066';
}
.icon-mv-local-touch:before {
	content: '\e067';
}
.icon-mv-museum:before {
	content: '\e068';
}
.icon-mv-photo-camera:before {
	content: '\e069';
}
.icon-mv-medal-round:before {
	content: '\e06a';
}
.icon-mv-last-ticket:before {
	content: '\e06b';
}
.icon-mv-whine-cheese:before {
	content: '\e06c';
}
.icon-mv-wine-bottle:before {
	content: '\2f';
}
.icon-mv-pax-add:before {
	content: '\e04f';
}
.icon-mv-briefcase:before {
	content: '\e02f';
}
.icon-mv-marriage:before {
	content: '\e054';
}
.icon-mv-luxury:before {
	content: '\e055';
}
.icon-mv-information-circle:before {
	content: '\e06d';
}
.icon-mv-time:before {
	content: '\e06e';
}
